@mixin body-body-l-bold {
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}

@mixin body-body-l-regular {
  font-family: Montserrat-Book, sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 2rem;
}

@mixin body-body-m-bold {
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin body-body-m-regular {
  font-family: Montserrat-Book, sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin body-body-s-bold {
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin body-body-s-regular {
  font-family: Montserrat-Book, sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin body-body-xs-bold {
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@mixin body-body-xs-regular {
  font-family: Montserrat-Book, sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@mixin heading-display {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 7rem;
  line-height: 8.75rem;
}

@mixin heading-display-mobile {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 6rem;
  line-height: 6.5rem;
}

@mixin heading-display-xl {
  font-family: Montserrat-Bold, sans-serif;
  font-weight: 700;
  font-size: 12.5rem;
  line-height: 12.5rem;
}

@mixin heading-display-xl-mobile {
  font-family: Montserrat-Bold, sans-serif;
  font-weight: 700;
  font-size: 12.5rem;
  line-height: 12.5rem;
}

@mixin heading-heading-l {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 4rem;
  line-height: 4.5rem;
}

@mixin heading-heading-l-mobile {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 3rem;
  line-height: 4rem;
}

@mixin heading-heading-m {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3.5rem;
}

@mixin heading-heading-m-mobile {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 2rem;
  line-height: 3rem;
}

@mixin heading-heading-s {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 3rem;
}

@mixin heading-heading-s-mobile {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 2.5rem;
}

@mixin heading-heading-xl {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 6rem;
  line-height: 6.5rem;
}

@mixin heading-heading-xl-mobile {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 3.5rem;
  line-height: 4rem;
}

@mixin heading-heading-xs {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 2.5rem;
}

@mixin heading-heading-xs-mobile {
  font-family: Montserrat-Light, sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin misc-button {
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

@mixin misc-subheading {
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 1.28px;
  text-transform: uppercase;
}

@mixin misc-subtitle {
  font-family: Montserrat-Medium, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
}
