@font-face {
  font-family: 'Montserrat-Bold';
  src:
    url('/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('/fonts/Montserrat-Bold.woff') format('woff'),
    url('/fonts/Montserrat-Bold.ttf') format('truetype'),
    url('/fonts/Montserrat-Bold.otf') format('truetype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Book';
  src:
    url('/fonts/Montserrat-Book.woff2') format('woff2'),
    url('/fonts/Montserrat-Book.woff') format('woff'),
    url('/fonts/Montserrat-Book.ttf') format('truetype'),
    url('/fonts/Montserrat-Book.otf') format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Light';
  src:
    url('/fonts/Montserrat-Light.woff2') format('woff2'),
    url('/fonts/Montserrat-Light.woff') format('woff'),
    url('/fonts/Montserrat-Light.ttf') format('truetype'),
    url('/fonts/Montserrat-Light.otf') format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src:
    url('/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat-Medium.woff') format('woff'),
    url('/fonts/Montserrat-Medium.ttf') format('truetype'),
    url('/fonts/Montserrat-Medium.otf') format('truetype');
  font-weight: 500;
  font-display: swap;
}
