:root {
  /* COLOR */
  --color-action-acquisition: #E9E3F9;
  --color-action-marketplace: #CAECF9;
  --color-action-primary: #221F61;
  --color-action-primary-hover: #6B30F6;
  --color-background-acquisition: #9479F7;
  --color-background-acquisition-dark: #6B30F6;
  --color-background-acquisition-light: #E9E3F9;
  --color-background-highlight: #9479F7;
  --color-background-highlight-dark: #6B30F6;
  --color-background-highlight-light: #E9E3F9;
  --color-background-marketplace: #77CEF2;
  --color-background-marketplace-dark: #4197BB;
  --color-background-marketplace-light: #CAECF9;
  --color-background-primary: #B5B4D2;
  --color-background-primary-dark: #63618F;
  --color-background-primary-darkest: #221F61;
  --color-background-primary-light: #E3E3EE;
  --color-background-primary-lightest: #F7F6FB;
  --color-background-primary-shady: #1D1A55;
  --color-background-primary-white: #FFF;
  --color-border-acquisition: #9479F7;
  --color-border-acquisition-dark: #6B30F6;
  --color-border-acquisition-light: #E9E3F9;
  --color-border-error: #FB6262;
  --color-border-highlight: #9479F7;
  --color-border-highlight-dark: #6B30F6;
  --color-border-highlight-light: #E9E3F9;
  --color-border-marketplace: #77CEF2;
  --color-border-marketplace-dark: #4197BB;
  --color-border-marketplace-light: #CAECF9;
  --color-border-primary: #B5B4D2;
  --color-border-primary-dark: #63618F;
  --color-border-primary-darkest: #221F61;
  --color-border-primary-light: #E3E3EE;
  --color-border-primary-lightest: #F7F6FB;
  --color-blue-300: #CAECF9;
  --color-blue-500: #77CEF2;
  --color-blue-700: #4197BB;
  --color-dark: #100F29;
  --color-navy-100: #F7F6FB;
  --color-navy-1000: #1D1A55;
  --color-navy-300: #E3E3EE;
  --color-navy-500: #B5B4D2;
  --color-navy-700: #63618F;
  --color-navy-900: #221F61;
  --color-purple-300: #E9E3F9;
  --color-purple-500: #9479F7;
  --color-purple-700: #6B30F6;
  --color-red: #FB6262;
  --color-turquoise-300: #A6E7E0;
  --color-turquoise-500: #4DD0C1;
  --color-turquoise-700: #13A695;
  --color-white: #FFF;
  --color-icon-acquisition: #6B30F6;
  --color-icon-highlight: #9479F7;
  --color-icon-marketplace: #4197BB;
  --color-icon-primary: #221F61;
  --color-icon-secondary: #63618F;
  --color-icon-white: #FFF;
  --color-text-acquisition: #6B30F6;
  --color-text-acquisition-alt: #9479F7;
  --color-text-dark: #100F29;
  --color-text-highlight: #6B30F6;
  --color-text-highlight-alt: #9479F7;
  --color-text-marketplace: #4197BB;
  --color-text-marketplace-alt: #77CEF2;
  --color-text-muted: #B5B4D2;
  --color-text-primary: #221F61;
  --color-text-primary-alt: #F7F6FB;
  --color-text-secondary: #63618F;
  --color-text-secondary-alt: #E3E3EE;
  --color-text-white: #FFF;
}
