@use '@/styles/specify/text-styles' as *;

@forward '@/styles/specify/text-styles'  as text-*;

// ------------------------------------------------------------
//  SPACING
// ------------------------------------------------------------

@function px2rem($px) {
  @return $px / 16 * 1rem;
}

@function getSpacing($size) {
  @return px2rem($size * 8);
}

@mixin width-fit-content() {
  width: fit-content;
  width: -moz-fit-content;
}

// ------------------------------------------------------------
//  CONTAINER
// ------------------------------------------------------------

$page__nav-height--lg: getSpacing(17);

$page__max-width: px2rem(1920);
$page__max-height: px2rem(1080);
$page__min-height: px2rem(768);

$page__padding--xs: getSpacing(3);
$page__padding--md: getSpacing(4);
$page__padding--lg: getSpacing(14);

// ------------------------------------------------------------
//  GRID
// ------------------------------------------------------------

$grid__gap--xs: getSpacing(3);
$grid__gap--md: getSpacing(4);
$grid__gap--lg: $grid__gap--md;

@mixin grid--xs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 $grid__gap--xs;
  padding: 0 $page__padding--xs;
  margin: 0 auto;
}

@mixin grid--md {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0 $grid__gap--md;
  padding: 0 $page__padding--md;
  margin: 0 auto;
}

@mixin grid--lg {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0 $grid__gap--lg;
  padding: 0 $page__padding--lg;
  margin: 0 auto;
  width: 100%;
  max-width: $page__max-width;
}

// ------------------------------------------------------------
//  GRID V2
// ------------------------------------------------------------

$grid__gap-v2: getSpacing(4);

@mixin grid--xs-v2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 $grid__gap-v2;
  padding: 0 12px;
  margin: 0 auto;
  width: 100%;
}

@mixin grid--md-v2 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0 $grid__gap-v2;
  padding: 0 $grid__gap-v2;
  margin: 0 auto;
  width: 100%;
}

@mixin grid--lg-v2 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0 $grid__gap-v2;
  padding: 0 $grid__gap-v2;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
}

// ------------------------------------------------------------
//  CENTERING
// ------------------------------------------------------------

@mixin grid--center() {
  display: grid;
  place-items: center;
}

@mixin flex--center() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// ------------------------------------------------------------
// BORDER RADIUS
// ------------------------------------------------------------

$border-radius: (
  'x-small': getSpacing(1.5),
  'small': getSpacing(2),
  'regular': getSpacing(3),
  'rounded': getSpacing(8),
  'card': getSpacing(2.5),
);

@function getRadius($key) {
  @return map-get($border-radius, $key);
}

// ------------------------------------------------------------
// SHADOWS
// ------------------------------------------------------------

$shadows: (
  'default': 20px 20px 40px rgba(34, 33, 93, 0.12),
  'shrunk': 20px 20px 40px -15px rgba(34, 33, 93, 0.12),
);

@function getShadow($key) {
  @return map-get($shadows, $key);
}

// ------------------------------------------------------------
// Z-INDEXES
// ------------------------------------------------------------

$zIndexes: (
  'wizard': 99,
  'navigation': 9997,
  'languages-menu': 9998,
  'splash-screen': 9999,
);

@function getZIndex($key) {
  @return map-get($zIndexes, $key);
}

// ------------------------------------------------------------
//  BREAKPOINTS
// ------------------------------------------------------------

$breakpoint--md: 768;
$breakpoint--lg: 1280;

@mixin media($size) {
  @if ($size == 'md') {
    @media (min-width: px2rem($breakpoint--md)) { @content; }
  } @else if ($size == 'lg') {
    @media (min-width: px2rem($breakpoint--lg)) { @content; }
  } @else {
    @error '$size is invalid';
  }
}

// ------------------------------------------------------------
//  GRADIENT
// ------------------------------------------------------------

@mixin gradient-purple-blue-r() {
  background-color: transparent;
  -webkit-text-fill-color: transparent;
  background-image: var(--gradient-purple-blue-r);
}

@mixin gradient-purple-bl() {
  background-color: transparent;
  -webkit-text-fill-color: transparent;
  background-image: var(--gradient-purple-bl);
}

$gradient--dark: linear-gradient(180deg, rgba(29, 26, 85, 0) 0%, var(--color-background-primary-shady) 100%);

// ------------------------------------------------------------
//  TEXT-GRADIENT
// ------------------------------------------------------------

@mixin text-gradient() {
  font-weight: normal;
  color: var(--color-text-highlight-alt);
  background: var(--text-text-highlight-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

// ------------------------------------------------------------
//  RESPONSIVE TEXTS
// ------------------------------------------------------------

@mixin text-heading-display-xl {
  @include heading-display-xl-mobile;
  @include media('md') {
    @include heading-display-xl;
  }
}

@mixin text-heading-display {
  @include heading-display-mobile;
  @include media('md') {
    @include heading-display;
  }
}

@mixin text-heading-heading-xl {
  @include heading-heading-xl-mobile;
  @include media('md') {
    @include heading-heading-xl;
  }
}

@mixin text-heading-heading-l {
  @include heading-heading-l-mobile;
  @include media('md') {
    @include heading-heading-l;
  }
}

@mixin text-heading-heading-m {
  @include heading-heading-m-mobile;
  @include media('md') {
    @include heading-heading-m;
  }
}

@mixin text-heading-heading-s {
  @include heading-heading-s-mobile;
  @include media('md') {
    @include heading-heading-s;
  }
}

@mixin text-heading-heading-xs {
  @include heading-heading-xs-mobile;
  @include media('md') {
    @include heading-heading-xs;
  }
}
