@use '@/styles/utils' as *;
@use '@/styles/specify/_colors' as *;
@use '@/styles/specify/_gradients' as *;
@use '@/styles/specify/_fonts' as *;

:root {
  --breakpoint--md: #{$breakpoint--md};
  --breakpoint--lg: #{$breakpoint--lg};
  --breakpoint--max: #{$page__max-width};
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
  font-weight: normal;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  color: var(--color-text-primary);
  margin: 0;
  padding: 0;
}

body {
  @include text-body-body-m-regular();
  margin: 0;
  padding: 0;
}

#__next {
  margin: 0;
  padding: 0;
}

main {
  position: relative;
  z-index: 2;
  background: var(--color-background-primary-white);
}

// footer {
//   position: sticky;
//   bottom: 0;
//   max-height: 100vh;
// }

a {
  text-decoration: inherit;
  color: inherit;
}

h1 {
  @include text-heading-heading-xl;
  font-weight: normal;
}

h2 {
  @include text-heading-heading-l;
}

h3 {
  @include text-heading-heading-m;
}

h4 {
  @include text-heading-heading-s;
}

h5 {
  @include text-heading-heading-xs;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}

svg {
  display: block;
}

button {
  display: block;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover {
    cursor: pointer;
  }

  &:focus:not(:focus-visible) {
    border-style: none;
    outline: none;
  }
}
