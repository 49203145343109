:root {
  /* GRADIENT */
  --action-action-acquisition-hover:
    linear-gradient(
      270deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --action-action-marketplace-hover:
    linear-gradient(
      270deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --border-border-active:
    linear-gradient(
      270deg,
      rgb(169, 235, 255) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-blue-b:
    linear-gradient(
      180deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --gradient-system-blue-bl:
    linear-gradient(
      225deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --gradient-system-blue-br:
    linear-gradient(
      135deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --gradient-system-blue-l:
    linear-gradient(
      270deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --gradient-system-blue-r:
    linear-gradient(
      90deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --gradient-system-blue-t:
    linear-gradient(
      0deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --gradient-system-blue-tl:
    linear-gradient(
      -45deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --gradient-system-blue-tr:
    linear-gradient(
      46deg,
      rgb(119, 206, 242) 0%,
      rgb(202, 236, 249) 100%
    );
  --gradient-system-purple-b:
    linear-gradient(
      180deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --gradient-system-purple-bl:
    linear-gradient(
      225deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --gradient-system-purple-blue-b:
    linear-gradient(
      0deg,
      rgb(169, 235, 255) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-purple-blue-bl:
    linear-gradient(
      45deg,
      rgb(169, 235, 255) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-purple-blue-br:
    linear-gradient(
      -45deg,
      rgb(119, 206, 242) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-purple-blue-l:
    linear-gradient(
      90deg,
      rgb(169, 235, 255) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-purple-blue-r:
    linear-gradient(
      270deg,
      rgb(169, 235, 255) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-purple-blue-t:
    linear-gradient(
      180deg,
      rgb(169, 235, 255) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-purple-blue-tl:
    linear-gradient(
      135deg,
      rgb(169, 235, 255) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-purple-blue-tr:
    linear-gradient(
      225deg,
      rgb(169, 235, 255) 0%,
      rgb(148, 121, 247) 100%
    );
  --gradient-system-purple-br:
    linear-gradient(
      135deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --gradient-system-purple-l:
    linear-gradient(
      270deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --gradient-system-purple-r:
    linear-gradient(
      90deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --gradient-system-purple-t:
    linear-gradient(
      0deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --gradient-system-purple-tl:
    linear-gradient(
      -45deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --gradient-system-purple-tr:
    linear-gradient(
      45deg,
      rgb(148, 121, 247) 0%,
      rgb(220, 220, 255) 99.98%
    );
  --gradient-system-turquoise-b:
    linear-gradient(
      180deg,
      rgb(77, 208, 193) 0%,
      rgb(196, 238, 234) 100%
    );
  --gradient-system-turquoise-bl:
    linear-gradient(
      225deg,
      rgb(77, 208, 193) 0%,
      rgb(196, 238, 234) 100%
    );
  --gradient-system-turquoise-blue-b:
    linear-gradient(
      0deg,
      rgb(119, 206, 242) 0%,
      rgb(77, 208, 193) 99.59%
    );
  --gradient-system-turquoise-blue-bl:
    linear-gradient(
      45deg,
      rgb(119, 206, 242) 0%,
      rgb(77, 208, 193) 99.59%
    );
  --gradient-system-turquoise-blue-br:
    linear-gradient(
      -45deg,
      rgb(119, 206, 242) 0%,
      rgb(77, 208, 193) 99.59%
    );
  --gradient-system-turquoise-blue-l:
    linear-gradient(
      90deg,
      rgb(119, 206, 242) 0%,
      rgb(77, 208, 193) 99.59%
    );
  --gradient-system-turquoise-blue-r:
    linear-gradient(
      270deg,
      rgb(119, 206, 242) 0%,
      rgb(77, 208, 193) 99.59%
    );
  --gradient-system-turquoise-blue-t:
    linear-gradient(
      180deg,
      rgb(119, 206, 242) 0%,
      rgb(77, 208, 193) 99.59%
    );
  --gradient-system-turquoise-blue-tl:
    linear-gradient(
      135deg,
      rgb(119, 206, 242) 0%,
      rgb(77, 208, 193) 99.59%
    );
  --gradient-system-turquoise-blue-tr:
    linear-gradient(
      225deg,
      rgb(119, 206, 242) 0%,
      rgb(77, 208, 193) 99.59%
    );
  --gradient-system-turquoise-br:
    linear-gradient(
      135deg,
      rgb(77, 208, 193) 0%,
      rgb(196, 238, 234) 100%
    );
  --gradient-system-turquoise-l:
    linear-gradient(
      90deg,
      rgb(196, 238, 234) 0.68%,
      rgb(77, 208, 193) 100%
    );
  --gradient-system-turquoise-r:
    linear-gradient(
      90deg,
      rgb(77, 208, 193) 0%,
      rgb(196, 238, 234) 100%
    );
  --gradient-system-turquoise-t:
    linear-gradient(
      0deg,
      rgb(77, 208, 193) 0%,
      rgb(196, 238, 234) 100%
    );
  --gradient-system-turquoise-tl:
    linear-gradient(
      135deg,
      rgb(196, 238, 234) 0.68%,
      rgb(77, 208, 193) 100%
    );
  --gradient-system-turquoise-tr:
    linear-gradient(
      45deg,
      rgb(77, 208, 193) 0%,
      rgb(196, 238, 234) 100%
    );
  --text-text-highlight-gradiant:
    linear-gradient(
      -45deg,
      rgb(119, 206, 242) 0%,
      rgb(148, 121, 247) 100%
    );
}
